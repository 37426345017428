.StatsModal {
    position: absolute;
    width: 35vw;
    height: 35vh;
    bottom: 0;
    right: 0vw;
    background-color: var(--primary);
    color: var(--texthighlighted);
    outline: none;
    transition: color .2s ease, background-color 0s ease;
    overflow: auto;
  }

  .stattab {
    width: 100%;
    bottom: 0;
    position: absolute;
  }

  .tabbtn {
    height: 3rem;
    font-size: 1.5em;
    width: 33.333%;
    color: var(--text);
    display: inline-block;
    line-height: 2rem;
    opacity: .7;
  }

  .tabbtnselected {
    height: 3rem;
    font-size: 1.5em;
    width: 33.333%;
    display: inline-block;
    color: var(--texthighlighted);
    line-height: 2rem;
  }

  .tabbtn:hover, .tabbtn:focus, .tabbtn:active {
    background-color: inherit;
    opacity: 1;
  }

  .tabbtnselected:hover, .tabbtnselected:focus, .tabbtnselected:active {
    background-color: inherit;
  }

  .graphpanel {
    bottom: 0;
    position: absolute;
    width: 98%;
    margin-left: 0%;
  }

  .statpanel {
    margin-top: 20px;
    position: absolute;
    width: 99%;
    font-size: 16px;
  }

  #stat {
    width: 33%;
  }

  .stat-table {
    width: 100%;
    color: var(--text);

  }

  .blanktable {
    margin: 25px 0px;
    display: block;
  }

  .dropdown {
    background-color: var(--primary);
    color: var(--text);
    border: none;
    width: 35vw;
    bottom: 36.5vh;
    right: 0;
    position: fixed;
  }

  .dropdown-menu {
    background-color: var(--primary);
    color: var(--text);
    border: none;
    box-shadow: none;
  }

  .dropdown-ctrl {
    background-color: var(--primary);
    color: var(--text);
    border: none;
    transition: color .3s ease, background-color 0s ease;
  }

  .dropdown-ctrl:hover {
    cursor: pointer;
    box-shadow: none;
  }

  .dropdown-place {
    margin-left: 40px;
  }

  .dropdown-item {
    background-color: var(--primary);
    color: var(--text);
    border: none;
    transition: color .2s ease, background-color 0s ease;
  }

  .dropdown-item:hover {
    background-color: var(--primary);
    color: var(--texthighlighted);
  }

  .dropdown-item.is-selected {
    background-color: var(--accent);
    color: var(--texthighlighted);
  }
