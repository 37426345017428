.Modal {
    position: absolute;
    top: 10vh;
    left: 10vw;
    right: 10vw;
    bottom: 10vh;
    background-color: var(--primary);
    color: var(--texthighlighted);
    outline: none;
    transition: .2s;
  }

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .setmodalinfo {
    margin-right: 0px;
    height: 100%;
  }

  .setmodalinfo > h {
    font-size: 48px;
  }

  .switches {
    float: right;
    padding-right: 30px;
    padding-top: 15px;
  }

  .setinput {
    background-color: rgb(0, 0, 0, .3);
    color: var(--texthighlighted);
    border-style: none;
    padding: 5px;
    width: 24px;
  }

  #changen {
    font-size: 28px;
    margin: -10px 5px -5px;
    color: var(--accent);
  }

  #changen:hover {
    color: var(--hover);
    cursor: pointer;
  }

  #changen:active {
    color: var(--click);
  }

  #icon {
    font-size: 2rem;
  }

  #icon:hover {
    background-color: inherit;
    border-radius: 10px;
  }

  .tabs {
    float: left;
    display: block;
    width: 15%;
    height: 100%;
    min-width: 100px;
    margin-right: 0px;
    margin-top: 0px;
  }

  .setting {
    color: var(--texthighlighted);
    width: 100%;
    display: inline-block;
    font-size: 1.25rem;
    height: 60px;
    margin: 0 0 0 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid;
    border-bottom-color: var(--text);
    border-bottom-width: 2px;
    overflow: hidden;
  }

  .desc {
    color: var(--text);
    margin-left: 20px;
    padding-bottom: 5px;
  }

  .presets {
    color: var(--texthighlighted);
    width: 100%;
    display: inline-block;
    font-size: 1.5rem;
    height: 120px;
    margin: 0 0 0 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid;
    border-bottom-color: var(--text);
    border-bottom-width: 2px;
    overflow: auto;
  }

  .select {
    margin-top: 30px;
    margin-left: 30px;
  }

  .custom {
    color: var(--texthighlighted);
    width: 100%;
    display: inline-block;
    font-size: 1.5rem;
    margin: 0 0 0 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 500px;
    border-bottom: solid;
    border-bottom-color: var(--text);
    border-bottom-width: 2px;
  }

  .theme {
    margin: 30px;
  }

  .colorbuttons {
    float: left;
    display: inline-block;
    width: 200px;
    margin-bottom: 50px;
    margin-right: 30px;
  }

  .colors {
    display: inline-block;
    margin: 0px 0px 50px 0px;
    overflow: auto;
  }

  .saved {
    overflow: auto;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    height: 200px;
    overflow-x: hidden;
  }

  .colorscontent {
    overflow: scroll;
    height: calc(80vh - 72px);
    overflow-x: hidden;
  }

  .unstyledlist {
    margin: 0px 30px;
    width: 100%;
    padding: 0px;
    overflow-x: hidden;
  }

  .listitem {
    display: inline-block;
    text-align: center;
    margin-right: 30px;
  }

  .themebtn {
    width: 160px;
    height: 42px;
    font-weight: 700;
    font-size: .65em;
    transition: .2s;
  }

  .themebtn:hover {
    cursor: pointer;
  }

  .deletetheme {
    display: block;
    width: 30px;
    height: 42px;
    margin-top: 30px;
    margin-left: 160px;
    margin-bottom: -42px;
  }

  .deletetheme:hover {
    color: var(--texthighlighted);
    background-color: inherit;
    cursor: pointer;
  }

  .deletetheme:active {
    color: var(--texthighlighted);
  }

  #tinyshift {
    margin-left: 15px;
  }

  .setlist {
    overflow: scroll;
    height: calc(80vh - 72px);
  }



  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    height: 100%;
  }

  .react-tabs__tab-list {
    margin: 0 0 0 0px;
    padding: 0;
  }

  .react-tabs__tab {
    color: var(--text);
    display: block;
    background: var(--tab);
    font-size: 1.5rem;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: .2s;
  }

  .react-tabs__tab:hover {
    background: var(--tabselect);
  }

  .react-tabs__tab--selected {
    background: var(--tabselect);
    color: var(--texthighlighted);
  }

  .react-tabs__tab--disabled {
    color: var(--texthighlighted);
    cursor: default;
    padding: 0px;
    font-size: 2rem;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .react-tabs__tab--disabled:hover {
    background-color: inherit;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  .react-tabs__tab-panel {
    display: none;
    overflow: hidden;
    margin: 0px;
  }

  .react-tabs__tab-panel--selected {
    display: block;
    margin-top: 0px;
    margin-left: 10px;
    height: 100%;

  }

  .pages {
    margin-top: 0px;
    display: inline-block;
    position: relative;
    width: calc(82% - 30px);;
    height: 100%;
    margin-right: 30px;
    overflow: auto;
    overflow-x: hidden;
  }

  .gray-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #282c34;
    --button-primary-color-dark: #0d0f11;
    --button-primary-color-light: #d5dce2;
    --button-primary-color-hover: #1f2228;
    --button-primary-color-active: #1d2026;
    --button-primary-border: 0px;
  }

  .dark-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #1a1c21;
    --button-primary-color-dark: #000000;
    --button-primary-color-light: #d5dce2;
    --button-primary-color-hover: #131519;
    --button-primary-color-active: #0d0e11;
    --button-primary-border: 0px;
  }

  .light-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #d5dce2;
    --button-primary-color-dark: #a2a6aa;
    --button-primary-color-light: #282c34;
    --button-primary-color-hover: #bfc4c9;
    --button-primary-color-active: #b8bcc1;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .blue-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #3da1ff;
    --button-primary-color-dark: #235f99;
    --button-primary-color-light: #0b1c2d;
    --button-primary-color-hover: #3186d6;
    --button-primary-color-active: #2a76bf;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .dark-blue-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #000b16;
    --button-primary-color-dark: #000;
    --button-primary-color-light: #91c2ff;
    --button-primary-color-hover: #00070f;
    --button-primary-color-active: #2a76bf;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .red-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #ff4949;
    --button-primary-color-dark: #b23333;
    --button-primary-color-light: #163859;
    --button-primary-color-hover: #e84343;
    --button-primary-color-active: #d13c3c;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .dark-red-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #1c0000;
    --button-primary-color-dark: #000;
    --button-primary-color-light: #ff6363;
    --button-primary-color-hover: #380b0b;
    --button-primary-color-active: #230707;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .primary {
    margin-right: 20px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 15px var(--accent);
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--primary);
    --button-primary-color-dark: var(--primary);
    --button-primary-color-light: var(--text);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--primary);
    --button-primary-border: 1px solid var(--text);
  }

  .secondary {
    margin-right: 20px;
    margin-bottom: 30px;
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--secondary);
    --button-primary-color-dark: var(--secondary);
    --button-primary-color-light: var(--primary);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--secondary);
    --button-primary-border: 0px solid var(--text);
  }

  .accent {
    margin-right: 20px;
    margin-bottom: 30px;
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--accent);
    --button-primary-color-dark: var(--accent);
    --button-primary-color-light: var(--texthighlighted);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--accent);
    --button-primary-border: 0px solid var(--texthighlighted);
  }

  .text {
    margin-right: 20px;
    margin-bottom: 30px;
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--text);
    --button-primary-color-dark: var(--text);
    --button-primary-color-light: var(--primary);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--text);
    --button-primary-border: 0px solid var(--texthighlighted);
  }

  .texthighlighted {
    margin-right: 20px;
    margin-bottom: 30px;
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--texthighlighted);
    --button-primary-color-dark: var(--texthighlighted);
    --button-primary-color-light: var(--primary);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--texthighlighted);
    --button-primary-border: 0px solid var(--texthighlighted);
  }
