.AccountModal {
    position: absolute;
    top: 2vh;
    left: 10vw;
    right: 10vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: scroll;
    bottom: 42vh;
    min-width: 250px;
    outline: none;
  }

.AccountOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  #accountbtn {
    font-size: 2rem;
  }

  #accountbtn:hover {
    background-color: inherit;
    border-radius: 10px;
  }

  .signinbtn {
    text-align: center;
    height: 50px;
    width: 240px;
    margin-top: 100px;
    margin-left: calc(50% - 120px);
  }

  .logout {
    text-align: center;
    bottom: calc(42vh + 30px);
    right: calc(10vw + 30px);
    position: fixed;
    width: 240px;
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 2px;
    background-color: var(--accent);
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 4px 0px;
    transition: box-shadow .2s ease;
  }

  .logout:hover {
    background-color: var(--accent);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px 0px;
    cursor: pointer;
  }

  .accountinfo {
    position: relative;
    margin: 10px;
    height: 450px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .signininfo {
    position: relative;
    font-size: 24px;
    text-align: center;
    margin: 0px 20px;
    height: 390px;
  }

  .signin {
    width: 20vw;
    text-align: center;
    justify-content: center;
  }

  .userinfo {
    margin: 30px;
  }

  .welcome {
    margin-top: 30px;
    display: inline-block;
    font-size: 30px;
  }

  .profilepic {
    width: 100px;
    margin-bottom: -30px;
    margin-right: 30px;
  }

  .accountdesc {
    font-size: 20px;
    margin-left: 20px;
    margin-top: 50px
  }

  .updownbtns {
    padding-right: 50px;
    padding-left: 50px;
  }

  .load-title {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 30px;
    display: inline-block;
  }

  .save-title {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 32px;
    display: inline-block;
  }


  .upbtnaccount {
    text-align: left;
    margin: 10px 0px;
  }

  .downbtnaccount {
    text-align: left;
  }

  .actload {
    text-align: center;
    display: inline-block;
    position: fixed;
  }

  .actsave {
    text-align: left;
    display: inline-block;
    position: fixed;
  }

  .timestamp-act {
    margin: 10px 20px;
    font-size: 20px;
  }
