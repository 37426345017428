.DownUpModal {
    position: absolute;
    top: 2vh;
    left: 15vw;
    right: 15vw;
    bottom: 30vh;
    background-color: var(--primary);
    color: var(--texthighlighted);
    overflow: hidden;
    min-width: 250px;
    outline: none;
  }

  .DownUpOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .downupinfo {
    margin: 0px;
    height: calc(68vh - 70px);
  }

  .transferbtn {
    float: right;
    margin-right: 30px;
  }

  .transfer {
    height: 150px;
  }

  .transferinfo {
    margin-left: 30px;
    width: 100%;
    display: inline-block;
  }

  #h2du {
    text-align: left;
    margin-left: 20px;
  }

  .lefttitle {
    width: 50%;
    display: inline-block;
    text-align: center;
    color: var(--text);
    margin: 10px 0px;
    height: 50px;
  }

  .righttitle {
    width: 50%;
    display: inline-block;
    text-align: center;
    color: var(--text);
    margin: 10px 0px;
    height: 50px;
  }

  .leftdu {
    margin: 0px;
    margin-left: 20px;
    width: calc(50% - 20px);
    float: left;
    color: var(--text);
    display: inline-block;
    font-size: 1rem;
    padding: 0px;
    padding-bottom: 10px;
    height: calc(68vh - 70px);
    overflow: auto;
    overflow-x: hidden;
  }

  .rightdu {
    margin: 0px;
    width: calc(50% - 20px);
    color: var(--text);
    display: inline-block;
    font-size: 1rem;
    padding: 0px;
    margin-left: 20px;
    padding-bottom: 10px;
    height: calc(68vh - 70px);
    overflow: auto;
    overflow-x: hidden;
  }

  .syncmessage {
    width: calc(100% - 60px);
  }

  .downinput {
    background-color: rgb(0, 0, 0, .2);
    color: var(--text);
    outline-color: var(--accent);
    font-size: 16px;
    border-style: none;
    padding: 5px;
    height: 30px;
    width: 250px;
  }

  .upinput {
    background-color: rgb(0, 0, 0, .2);
    color: var(--text);
    outline-color: var(--accent);
    font-size: 16px;
    text-align: left;
    border-style: none;
    display: inline;
    padding: 5px;
    height: 40px;
    width: 260px;
  }

  .signinmsg {
    margin-left: 20px;
    color: var(--text);
  }

  .loadfire {
    width: 500px;
    margin-right: 100px;
    display: inline-block;
  }

  h1 {
    margin: 0px;
  }

  #clear {
    font-size: 1vw;
  }

  #downicon {
    padding-top: 1px;
  }

  .undodu {
    display: inline-block;
    float: left;
    margin-left: -150px;
    margin-top: 7px;
  }

  .timestamp {
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 28px;
  }

  .downloadbtn {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: var(--accent);
    --button-primary-color-dark: var(--dark);
    --button-primary-color-light: var(--texthighlighted);
    --button-primary-color-hover: var(--hover);
    --button-primary-color-active: var(--click);
    --button-primary-border: 0px solid #FFFFFF;
  }

  .firebasebtn {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: var(--accent);
    --button-primary-color-dark: var(--dark);
    --button-primary-color-light: var(--texthighlighted);
    --button-primary-color-hover: var(--hover);
    --button-primary-color-active: var(--click);
    --button-primary-border: 0px solid #FFFFFF;
  }
