.SessionModal {
    position: absolute;
    top: 15vh;
    left: 40vw;
    right: 40vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: auto;
    height: 70vh;
    min-width: 300px;
    outline: none;
  }

.SessionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
}


#titlesess {
  height: 45px;
  padding-top: 15px;
  margin: 0px;
  position: relative;
  color: var(--texthighlighted);
  text-align: center;
}

.sessions {

  display: block;
  height: calc(70vh - 141px);
  width: 100%;
  overflow: auto;
  background-color: var(--buttonhover);
}

.sesslist {
  list-style-type: none;
  height: calc(70vh - 141px);
  overflow: scroll;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.addsess {
  background-color: inherit;
  position: fixed;
  display: block;
  height: 60px;
  width: 20vw;
  min-width: 300px;
  text-align: center;
}

.sessionbtn {
  text-align: center;
  width: 100%;
  height: 80px;
  margin: 0;
}

.choosesess {
  margin: 0;
}

.sessionname {
  margin: 10px 0px;
  float: left;
  width: 10%;
}

.midsess {
  margin: 0px;
  width: 80%;
  display: inline-block;
}

.newsess {
  width: 100%;
  margin: 0px;
}

.newsess:hover {
  background-color: inherit;
  color: var(--texthighlighted);
}

.deletesess {
  margin-bottom: 6px;
}

.deletesess:hover {
  color: var(--texthighlighted);
}
