.CustomModal {
    position: absolute;
    top: 12vh;
    left: 25vw;
    right: 25vw;
    background-color: var(--primary);
    color: var(--texthighlighted);
    overflow: scroll;
    height: 250px;
    min-width: 250px;
    outline: none;
  }

.ArrowOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .clearinfo {
    position: relative;
    margin: 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .choose {
    position: absolute;
    top: 180px;
    display: inline-block;
    right: 1%;
  }

  .cancel {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  .nameinput {
    background-color: rgb(0, 0, 0, .2);
    color: var(--text);
    outline-color: var(--accent);
    font-size: 16px;
    border-style: none;
    margin: 20px;
    padding: 5px;
    height: 30px;
    width: 250px;
    margin-left: 1vw;
  }

#title {
    margin: 0px;
    color: var(--texthighlighted);
  }

  #clear {
    font-size: 1vw;
  }


  .savetheme {
    margin-right: 20px;
    margin-top: 0px;
    margin-left: 25px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: var(--accent);
    --button-primary-color-dark: var(--dark);
    --button-primary-color-light: var(--texthighlighted);
    --button-primary-color-hover: var(--hover);
    --button-primary-color-active: var(--click);
    --button-primary-border: 0px solid var(--texthighlighted);
  }
