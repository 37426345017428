#log {
  justify-content: left;
  margin-left: 0px;
  width: 15vw;
  height: 100vh;
  background-color: inherit;
  display: block;
  position: absolute;
  align-items: left;
  color: var(--text);
  transition: color .5s, background-color 0s;
  font-size: 16px;
  min-width: 200px;
  overflow: hidden;
}

#log:hover {
  color: var(--highlight);
}

.logstats {
  background-color: var(--primary);
  width: 15vw;
  height: 317px;
  display: inline-block;
  min-width: 200px;
  overflow: hidden;
}

.scroll {
  display: block;
  overflow-x: hidden;
  position: relative;
  height: calc(100vh - 364px);
  border-top-style: solid;
  border-width: 1px;
  margin-right: -30px;
}

.av {
  background-color: var(--primary);
  display: block;
  position: fixed;
  align-items: center;
  bottom: 0;
  width: 15vw;
  height: 30px;
  padding-top: 10px;
  border-top-style: solid;
  border-width: 1px;
  min-width: 200px;
  overflow: hidden;
  justify-content: center;
}

.row {
  display: flex;
}

.toprow {
  font-weight: lighter;
  margin-right: 0vw;
  display: flex;
  justify-content: left;
}

.quarter {
  width: 3.5vw;
  display: inline-block;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  min-width: 47px;
  cursor: default;
}

button:hover {
  cursor: pointer;
}

.results {
  width: 100%;
}

button {
  background-color: inherit;
  opacity: 100;
  width: 100%;
  height:100%;
  display: block;
  outline: none;
  border: none;
  padding: 5px;
  color: inherit;
  font-size: 15px;
}

button:hover {
  background-color: rgba(0, 0, 0, .2);
}

button:hover #step {
  display:none;
}

button #step {
  display: block;
}

button:hover #delete {
  display: block;
  font-weight: lighter;
}

button #delete {
  display: none;
}

#settings {
  background-color: inherit;
  font-size: 10px;
}

ul {
  list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .1);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, .2);
}
