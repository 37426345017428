.dothings {
  margin-top: 10px;
}

.topstats {
  transition: 0s;
}

h4 {
  margin-bottom: 15px;
  cursor: pointer;
}

#columnlabel {
  font-weight: lighter;
}

#rowlabel {
  font-weight: lighter;
}

#logstatbtn {
  font-size: 18px;
}

.sessionbtn {
  background-color: inherit;
  font-weight: lighter;
  cursor: default;
}

.sessionbtn:hover {
  background-color: inherit;
}

.third {
  width: 5vw;
  display: inline-block;
  margin: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  align-items: center;
  justify-content: center;
  min-width: 65px;
}

#sessclick:hover {
  cursor: pointer;
}
