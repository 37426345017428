.DeleteModal {
    position: absolute;
    top: 2vh;
    left: 25vw;
    right: 25vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: scroll;
    height: 500px;
    min-width: 250px;
    outline: none;
  }

.DeleteOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .deleted {
    width: 50vw;
    min-width: 250px;
    position: fixed;
    left: 25vw;
    display: block;
    top: calc(2vh + 140px);
    height: 280px;
    background-color: var(--buttonhover);
    overflow: scroll;
  }

  .half {
    height: 30px;
    width: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .clearinfo {
    position: relative;
    margin: 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .choosedelete {
    position: absolute;
    top: 430px;
    display: inline-block;
    right: 1%;
  }

  .half {
    width: 25vw;
    display: inline-vlock;
    min-width: 125px;
    align-items: center;
    justify-content: center;
  }

  .cancel {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  #input {
    background-color: rgb(0, 0, 0, .3);
    color: var(--texthighlighted);
    border-style: none;
    width: 40px;
    margin: 2px;
    padding: 3px;
  }

  #title {
    margin: 0px;
    color: var(--texthighlighted);
  }

  #inline {
    display: inline-block;
    -webkit-tap-highlight-color:transparent;
  }

  #inline2 {
    display: inline-block;
    margin-left: 10px;
    -webkit-tap-highlight-color:transparent;
  }

#clear {
  font-size: 1vw;
}
