.TimeModal {
    position: absolute;
    top: 2vh;
    left: 20vw;
    right: 20vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: scroll;
    height: 500px;
    min-width: 250px;
    outline: none;
  }

.TimeOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .averageinfo {
    position: relative;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .avinfo {
    margin: 30px 0px 30px -1vw;
    font-size: 1.5rem;
  }

  .category-time {
    width: 100px;
    text-align: left;
  }

  .category-solve {
    width: 100px;
    text-align: center;
  }

  .tablehead {
    height: 2em;
    text-align: left;
    position: fixed;
    margin-top: -40px;
    padding-bottom: 10px;
    font-size: 24px;
  }

  .tableview {
    display: block;
    background-color: var(--buttonhover);
    height: 300px;
    width: 60vw;
    overflow: scroll;
    font-size: 16px;
  }

  .graphview {
    display: block;
    height: 300px;
    width: 60vw;
    overflow: scroll;
    font-size: 16px;
  }

  .avbuttons {
    position: absolute;
    top: 440px;
    display: inline-block;
    right: 1vw;
  }

  .avstats {
    position: fixed;
    font-size: 24px;
    width: 10px;
    height: 10px;
    top: calc(2vh + 80px);
    right: calc(20vw + 70px);
  }

  .avstats:hover, .avstats:focus {
    background-color: var(--primary);
  }

  .copy {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  #titleav {
    margin: 0px;
    margin-left: .5vw;
    font-size: 40px;
    color: var(--texthighlighted);
  }

  #clear {
    font-size: 1vw;
  }

  .on {
    color: var(--accent);
    transition: color .3s ease, background-color 0s ease;
  }

  .off {
    color: var(--text);
    transition: color .3s ease, background-color 0s ease;
  }

  .off:hover {
    color: var(--texthighlighted);
    transition: color .3s ease, background-color 0s ease;
  }
