.ArrowModal {
    position: absolute;
    top: 2vh;
    left: 25vw;
    right: 25vw;
    background-color: var(--primary);
    color: var(--texthighlighted);
    overflow: scroll;
    height: 250px;
    min-width: 250px;
    outline: none;
  }

.ArrowOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .clearinfo {
    position: relative;
    margin: 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .choose {
    position: absolute;
    top: 180px;
    display: inline-block;
    right: 1%;
  }

  .cancel {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  #input {
    background-color: rgb(0, 0, 0, .3);
    color: var(--texthighlighted);
    border-style: none;
    width: 60px;
    margin: 2px;
    padding: 3px;
    transition: border-color .3s ease;
  }

  #changen {
    font-size: 28px;
    margin: -10px 5px -5px;
    color: var(--accent);
  }

  #changen:hover {
    color: var(--hover);
    cursor: pointer;
  }

  #changen:active {
    color: var(--click);
  }

  #title {
    margin: 0px;
    color: var(--texthighlighted);
  }

  #inline {
    display: inline-block;
  }

  #inline2 {
    display: inline-block;
    margin-left: 10px;
  }