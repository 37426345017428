:root {
  --primary: #1a1c21;
  --secondary: #efefef;
  --accent: #9621ff;
  --text: rgba(255, 255, 255, .6);
  --texthighlighted: #efefef;
  --dark: #0d0f11;
  --hover: #1f2228;
  --click: #1d2026;

  --tabselect: rgba(0, 0, 0, .3);
  --buttonhover: rgba(0, 0, 0, .2);
  --tab: var(--primary);

  --highlight: var(--texthighlighted);
}

.timer {
  text-align: center;
  background-color: var(--primary);
  transition: 0s;
  color: var(--secondary);
}

#time {
  color: inherit;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 144px;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#average {
  bottom: 0vh;
  left: 30vw;
  right: 30vw;
  height: flex;
  background-color: inherit;
  display: block;
  padding-bottom: 20px;
  position: absolute;
  align-items: center;
  vertical-align: bottom;
  color: var(--text);
  transition: color .5s, background-color 0s;
  font-size: 24px;
  min-width: 200px;
  cursor: default;
}

#average:hover {
  color: var(--highlight);
  cursor: default;
}

#scramble {
  left: 20vw;
  right: 20vw;
  height: flex;
  max-height: 30vh;
  background-color: inherit;
  display: block;
  position: absolute;
  align-items: center;
  color: var(--text);
  transition: color .5s, background-color 0s;
  font-size: 28px;
  min-width: 200px;
}

#scramble:hover {
  color: var(--highlight);
}

#settings {
  top: 3em;
  right: 1vw;
  background-color: inherit;
  display: block;
  position: absolute;
  color: var(--text);
  transition: color .3s ease, background-color 0s ease;
}

#settings:hover {
  color: var(--highlight);
}

#icon:hover, #accountbtn:hover, #statsicon:hover {
  cursor: pointer;
}

#account {
  top: 10em;
  right: 1vw;
  background-color: inherit;
  display: block;
  position: absolute;
  color: var(--text);
  transition: color .3s ease, background-color 0s ease;
}

#account:hover {
  color: var(--highlight)
}

#stats {
  top: 6em;
  right: 1vw;
  background-color: inherit;
  display: block;
  position: absolute;
  color: var(--text);
  transition: 0s;
}

#statsicon {
  font-size: 1.9rem;
  transition: color .3s ease, background-color 0s ease;
}

#statsicon:hover {
  background-color: inherit;
  border-radius: 10px;
  cursor: pointer;
  color: var(--highlight);
  transition: color .3s ease, background-color 0s ease;
}

#statsiconon {
  font-size: 1.9rem;
  color: var(--accent);
  transition: color .3s ease, background-color 0s ease;
}

#statsiconon:hover {
  background-color: inherit;
  border-radius: 10px;
  cursor: pointer;
}

#undoload {
  bottom: 0vh;
  width: 118px;
  height: 80px;
  display: block;
  position: absolute;
  right: calc(50vw - 59px);
  background-color: rgba(0, 0, 0, 0);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

input {
  transition: borderColor .3s ease;
}

@media screen and (max-width: 1080px) {

    #average {
      bottom: 0%;
      left: 0vw;
      right: 0vw;
      height: 10%;
      transition: 0s;
      font-size: 24px;
      width: 100vw;
    }

    #scramble {
      left: 15%;
      right: 15%;
    }

    .scramble-dropdown {
      width: 100%;
    }

    .log {
      top: 100%;
      width: 100vw !important;
    }

    .logstats {
      width: 100vw !important;
    }

    .scroll {
      width: 100vw !important;
    }

    .av {
      width: 100vw;
      position: absolute;
    }

    .third {
      width: 33%;
    }

    .quarter {
      width: 25%;
    }

    .StatsModal {
      bottom: 250%;
      top: 200%;
      width: 100vw;
    }

    .dropdown {
      width: 100vw;
      float: left;
      position: absolute;
      top: 0%;
      bottom: auto;
    }

    .stat-table {
      margin-top: 37px;
    }

    .Modal {
      top: 10%;
      bottom: 10%;
      left: 5%;
      right: 5%;
      float: right;
    }

    .presets {
      height: 50%;
    }

    .theme {
      display: none;
    }

    .tabs {
      display: inline-block;
      width: 100%;
      height: 135px;
      min-width: 100px;
      margin-right: 0px;
      margin-top: 0px;
    }

    .react-tabs__tab {
      display: inline-block;
      width: 33.3%;
    }

    .pages {
      width: 86vw;
      margin: 0px 2vw 0px 2vw;
      height: calc(100% - 135px);
    }

    .react-tabs__tab-panel--selected {
      margin-left: 0px;
      overflow: auto;
    }

    h2 {
      display: none;
    }

    .colorscontent {
      text-align: center;
    }

    .presets {
      height: 45%;
      margin: 0px;
      overflow: auto;
    }

    .custom {
      text-align: center;
      margin: 0px;
    }

    .colorbuttons {
      float: none;
      margin: 0px;
      margin-bottom: 30px;
    }

    .saved {
      height: 300px;
      text-align: center;
    }

    .btnlist {
      margin: 0px;
    }

    .unstyledlist {
      margin: 0px;
    }

    .listitem {
      margin: 0px 20px;
    }

    .setting {
      height: auto;
      width: 86vw;
    }

    .AccountModal {
      height: 80%;
    }

    .logout {
      bottom: 21%;
    }

    .SessionModal {
      right: 10vw;
      left: 10vw;
    }

    .addsess {
      width: 80vw;
    }

    .DeleteSessionModal {
      right: 10vw;
      left: 10vw;
    }

    .DownUpModal {
      top: 2%;
      left: 2%;
      right: 2%;
      bottom: 20%;
      height: auto;
    }

    .downupinfo {
      margin: 0px;
      height: calc(100% - 50px);
      overflow: auto;
    }

    .transferbtn {
      float: right;
      margin-right: 30px;
      width: 88px;
    }

    .transfer {
      height: 150px;
    }

    .transferinfo {
      margin-left: 30px;
      width: 100%;
      display: inline-block;
    }

    #h2du {
      text-align: left;
      margin-left: 20px;
      display: block;
    }

    .lefttitle {
      width: 50%;
      display: inline-block;
      text-align: center;
      color: var(--text);
      margin: 10px 0px;
    }

    .righttitle {
      width: 50%;
      display: inline-block;
      text-align: center;
      color: var(--text);
      margin-top: 10px;
    }

    .leftdu {
      width: calc(100% - 20px);
      color: var(--text);
      display: inline-block;
      height: auto;
    }

    .rightdu {
      width: calc(100% - 20px);
      color: var(--text);
      display: inline-block;
      height: auto;
    }

    .ArrowModal {
      top: 1%;
      left: 2%;
      right: 2%;
    }

    .TimeModal {
      left: 2%;
      right: 2%;
    }

    .averageinfo {
      padding: 1% 1% 1% 1%;
    }

    .avinfo {
      margin: 30px -1% 30px -1%;
    }

    .tableview {
      width: 100%;
    }

    .graphview {
      width: 100%;
    }

    .avstats {
      top: calc(1% + 80px);
      right: calc(2% + 34px);
    }

    .AboutModal {
      height: auto;
      top: 2%;
      left: 2%;
      right: 2%;
      bottom: 30%;
    }

    .averageinfo {
      height: 98%;
    }

    .avbuttons {
      top: calc(100% - 50px);
    }

    .AccountModal {
      top: 2%;
      right: 2%;
      left: 2%;
      bottom: 15%;
      height: auto;
    }

    .accountinfo {
      height: calc(100% - 35px);
      overflow-x: hidden;
    }

    .accountdesc {
      margin-top: 20px;
    }

    .userinfo {
      margin: 10px;
    }

    .downloadbtn {
      margin: 0;
    }

    .logout {
      position: relative;
      display: inline-block;
      margin-left: calc(100% - 180px);
      margin-top: 30px;
      margin-bottom: 30px;
    }

  }
