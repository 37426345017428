.TimeModal {
    position: absolute;
    top: 2vh;
    left: 20vw;
    right: 20vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: scroll;
    height: 500px;
    min-width: 250px;
    outline: none;
  }

.TimeOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .clearinfo {
    position: relative;
    margin: 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .timeinfo {
    margin: 30px;
    margin-top: 60px;
    font-size: 1.5rem;
  }

  .timeinfo:hover {
    background-color: inherit;
  }

  .plus2btnoff {
    color: var(--text);
    transition: color .3s ease, opacity .3s ease, background-color 0s ease;
    opacity: .5
  }

  .plus2btnon {
    color: var(--accent);
    transition: color .3s ease, background-color 0s ease;
  }

  .plus2btn, .plus2btnon, .plus2btnoff {
    width: 50px;
    font-size: 1.1em;
    display: inline-block;
    transition: color .3s ease, opacity .3s ease, background-color 0s ease;
    margin: 30px 6vw 30px 0vw;
  }

  .plus2btn:hover {
    background-color: var(--primary);
  }

  .plus2btnon:hover, .plus2btnoff:hover {
    cursor: pointer;
    opacity: .9;
    transition: opacity .3s ease, color .3s ease, background-color 0s ease;
  }

  .plus2btnon:hover, .plus2btnoff:hover {
    background-color: inherit;
  }

  .btncenter {
    display: flex;
    justify-content: center;
  }

  .timestats {
    font-size: .9em;
  }

  .okay {
    position: absolute;
    top: 430px;
    display: inline-block;
    right: 1%;
  }

  .copy {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  #titletime {
    margin: 0px;
    font-size: 40px;
    color: var(--texthighlighted);
  }

  #clear {
    font-size: 1vw;
  }
