body {
  margin: 0;
  padding: 0;
  font-family: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ArrowModal {
    position: absolute;
    top: 2vh;
    left: 25vw;
    right: 25vw;
    background-color: var(--primary);
    color: var(--texthighlighted);
    overflow: scroll;
    height: 250px;
    min-width: 250px;
    outline: none;
  }

.ArrowOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .clearinfo {
    position: relative;
    margin: 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  #title {
    margin: 0px;
    color: var(--texthighlighted);
  }

  .clearinfo > p {
    margin-left: 3%;
  }

  .choose {
    position: absolute;
    top: 180px;
    display: inline-block;
    right: 1%;
  }

  .cancel {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

.ArrowModal {
    position: absolute;
    top: 2vh;
    left: 25vw;
    right: 25vw;
    background-color: var(--primary);
    color: var(--texthighlighted);
    overflow: scroll;
    height: 250px;
    min-width: 250px;
    outline: none;
  }

.ArrowOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .clearinfo {
    position: relative;
    margin: 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .choose {
    position: absolute;
    top: 180px;
    display: inline-block;
    right: 1%;
  }

  .cancel {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  #input {
    background-color: rgb(0, 0, 0, .3);
    color: var(--texthighlighted);
    border-style: none;
    width: 60px;
    margin: 2px;
    padding: 3px;
    transition: border-color .3s ease;
  }

  #changen {
    font-size: 28px;
    margin: -10px 5px -5px;
    color: var(--accent);
  }

  #changen:hover {
    color: var(--hover);
    cursor: pointer;
  }

  #changen:active {
    color: var(--click);
  }

  #title {
    margin: 0px;
    color: var(--texthighlighted);
  }

  #inline {
    display: inline-block;
  }

  #inline2 {
    display: inline-block;
    margin-left: 10px;
  }
.undo {
  width: 00;
  height: 100;
  text-align: center;
  background-color: inherit;
}

.DownUpModal {
    position: absolute;
    top: 2vh;
    left: 15vw;
    right: 15vw;
    bottom: 30vh;
    background-color: var(--primary);
    color: var(--texthighlighted);
    overflow: hidden;
    min-width: 250px;
    outline: none;
  }

  .DownUpOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .downupinfo {
    margin: 0px;
    height: calc(68vh - 70px);
  }

  .transferbtn {
    float: right;
    margin-right: 30px;
  }

  .transfer {
    height: 150px;
  }

  .transferinfo {
    margin-left: 30px;
    width: 100%;
    display: inline-block;
  }

  #h2du {
    text-align: left;
    margin-left: 20px;
  }

  .lefttitle {
    width: 50%;
    display: inline-block;
    text-align: center;
    color: var(--text);
    margin: 10px 0px;
    height: 50px;
  }

  .righttitle {
    width: 50%;
    display: inline-block;
    text-align: center;
    color: var(--text);
    margin: 10px 0px;
    height: 50px;
  }

  .leftdu {
    margin: 0px;
    margin-left: 20px;
    width: calc(50% - 20px);
    float: left;
    color: var(--text);
    display: inline-block;
    font-size: 1rem;
    padding: 0px;
    padding-bottom: 10px;
    height: calc(68vh - 70px);
    overflow: auto;
    overflow-x: hidden;
  }

  .rightdu {
    margin: 0px;
    width: calc(50% - 20px);
    color: var(--text);
    display: inline-block;
    font-size: 1rem;
    padding: 0px;
    margin-left: 20px;
    padding-bottom: 10px;
    height: calc(68vh - 70px);
    overflow: auto;
    overflow-x: hidden;
  }

  .syncmessage {
    width: calc(100% - 60px);
  }

  .downinput {
    background-color: rgb(0, 0, 0, .2);
    color: var(--text);
    outline-color: var(--accent);
    font-size: 16px;
    border-style: none;
    padding: 5px;
    height: 30px;
    width: 250px;
  }

  .upinput {
    background-color: rgb(0, 0, 0, .2);
    color: var(--text);
    outline-color: var(--accent);
    font-size: 16px;
    text-align: left;
    border-style: none;
    display: inline;
    padding: 5px;
    height: 40px;
    width: 260px;
  }

  .signinmsg {
    margin-left: 20px;
    color: var(--text);
  }

  .loadfire {
    width: 500px;
    margin-right: 100px;
    display: inline-block;
  }

  h1 {
    margin: 0px;
  }

  #clear {
    font-size: 1vw;
  }

  #downicon {
    padding-top: 1px;
  }

  .undodu {
    display: inline-block;
    float: left;
    margin-left: -150px;
    margin-top: 7px;
  }

  .timestamp {
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 28px;
  }

  .downloadbtn {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: var(--accent);
    --button-primary-color-dark: var(--dark);
    --button-primary-color-light: var(--texthighlighted);
    --button-primary-color-hover: var(--hover);
    --button-primary-color-active: var(--click);
    --button-primary-border: 0px solid #FFFFFF;
  }

  .firebasebtn {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: var(--accent);
    --button-primary-color-dark: var(--dark);
    --button-primary-color-light: var(--texthighlighted);
    --button-primary-color-hover: var(--hover);
    --button-primary-color-active: var(--click);
    --button-primary-border: 0px solid #FFFFFF;
  }

.AboutModal {
    position: absolute;
    top: 2vh;
    left: 20vw;
    right: 20vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: scroll;
    height: 500px;
    min-width: 250px;
    transition: .2s;
    outline: none;
  }

.AboutOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

.about {
  margin: 10px 20px;
  font-size: 18px;
}

a {
  color: var(--texthighlighted);
}

.DeleteSessionModal {
    position: absolute;
    top: 15vh;
    left: 40vw;
    right: 40vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: auto;
    height: 70vh;
    min-width: 300px;
    outline: none;
    transition: 0s;
  }

.DeleteSessionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dsinfo {
  margin-left: 10px;
}

  .deletesessinfo {
    position: relative;
    margin: 20px 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .sesschoose {
    position: absolute;
    top: calc(70vh - 80px);
    display: inline-block;
    right: 1%;
  }

  .sesscancel {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .sessconfirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  .input {
    background-color: rgb(0, 0, 0, .3);
    color: var(--texthighlighted);
    border-style: none;
    padding: 5px;
    width: 2vw;
    margin-left: 1vw;
  }

  #dstitle {
    margin: 0px;
    font-size: 28px;
    color: var(--texthighlighted);
  }

#clear {
  font-size: 1vw;
}

.SessionModal {
    position: absolute;
    top: 15vh;
    left: 40vw;
    right: 40vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: auto;
    height: 70vh;
    min-width: 300px;
    outline: none;
  }

.SessionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
}


#titlesess {
  height: 45px;
  padding-top: 15px;
  margin: 0px;
  position: relative;
  color: var(--texthighlighted);
  text-align: center;
}

.sessions {

  display: block;
  height: calc(70vh - 141px);
  width: 100%;
  overflow: auto;
  background-color: var(--buttonhover);
}

.sesslist {
  list-style-type: none;
  height: calc(70vh - 141px);
  overflow: scroll;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.addsess {
  background-color: inherit;
  position: fixed;
  display: block;
  height: 60px;
  width: 20vw;
  min-width: 300px;
  text-align: center;
}

.sessionbtn {
  text-align: center;
  width: 100%;
  height: 80px;
  margin: 0;
}

.choosesess {
  margin: 0;
}

.sessionname {
  margin: 10px 0px;
  float: left;
  width: 10%;
}

.midsess {
  margin: 0px;
  width: 80%;
  display: inline-block;
}

.newsess {
  width: 100%;
  margin: 0px;
}

.newsess:hover {
  background-color: inherit;
  color: var(--texthighlighted);
}

.deletesess {
  margin-bottom: 6px;
}

.deletesess:hover {
  color: var(--texthighlighted);
}

.dothings {
  margin-top: 10px;
}

.topstats {
  transition: 0s;
}

h4 {
  margin-bottom: 15px;
  cursor: pointer;
}

#columnlabel {
  font-weight: lighter;
}

#rowlabel {
  font-weight: lighter;
}

#logstatbtn {
  font-size: 18px;
}

.sessionbtn {
  background-color: inherit;
  font-weight: lighter;
  cursor: default;
}

.sessionbtn:hover {
  background-color: inherit;
}

.third {
  width: 5vw;
  display: inline-block;
  margin: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  align-items: center;
  justify-content: center;
  min-width: 65px;
}

#sessclick:hover {
  cursor: pointer;
}

.DeleteModal {
    position: absolute;
    top: 2vh;
    left: 25vw;
    right: 25vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: scroll;
    height: 500px;
    min-width: 250px;
    outline: none;
  }

.DeleteOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .deleted {
    width: 50vw;
    min-width: 250px;
    position: fixed;
    left: 25vw;
    display: block;
    top: calc(2vh + 140px);
    height: 280px;
    background-color: var(--buttonhover);
    overflow: scroll;
  }

  .half {
    height: 30px;
    width: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .clearinfo {
    position: relative;
    margin: 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .choosedelete {
    position: absolute;
    top: 430px;
    display: inline-block;
    right: 1%;
  }

  .half {
    width: 25vw;
    display: inline-vlock;
    min-width: 125px;
    align-items: center;
    justify-content: center;
  }

  .cancel {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  #input {
    background-color: rgb(0, 0, 0, .3);
    color: var(--texthighlighted);
    border-style: none;
    width: 40px;
    margin: 2px;
    padding: 3px;
  }

  #title {
    margin: 0px;
    color: var(--texthighlighted);
  }

  #inline {
    display: inline-block;
    -webkit-tap-highlight-color:transparent;
  }

  #inline2 {
    display: inline-block;
    margin-left: 10px;
    -webkit-tap-highlight-color:transparent;
  }

#clear {
  font-size: 1vw;
}

.TimeModal {
    position: absolute;
    top: 2vh;
    left: 20vw;
    right: 20vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: scroll;
    height: 500px;
    min-width: 250px;
    outline: none;
  }

.TimeOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .clearinfo {
    position: relative;
    margin: 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .timeinfo {
    margin: 30px;
    margin-top: 60px;
    font-size: 1.5rem;
  }

  .timeinfo:hover {
    background-color: inherit;
  }

  .plus2btnoff {
    color: var(--text);
    transition: color .3s ease, opacity .3s ease, background-color 0s ease;
    opacity: .5
  }

  .plus2btnon {
    color: var(--accent);
    transition: color .3s ease, background-color 0s ease;
  }

  .plus2btn, .plus2btnon, .plus2btnoff {
    width: 50px;
    font-size: 1.1em;
    display: inline-block;
    transition: color .3s ease, opacity .3s ease, background-color 0s ease;
    margin: 30px 6vw 30px 0vw;
  }

  .plus2btn:hover {
    background-color: var(--primary);
  }

  .plus2btnon:hover, .plus2btnoff:hover {
    cursor: pointer;
    opacity: .9;
    transition: opacity .3s ease, color .3s ease, background-color 0s ease;
  }

  .plus2btnon:hover, .plus2btnoff:hover {
    background-color: inherit;
  }

  .btncenter {
    display: flex;
    justify-content: center;
  }

  .timestats {
    font-size: .9em;
  }

  .okay {
    position: absolute;
    top: 430px;
    display: inline-block;
    right: 1%;
  }

  .copy {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  #titletime {
    margin: 0px;
    font-size: 40px;
    color: var(--texthighlighted);
  }

  #clear {
    font-size: 1vw;
  }

.TimeModal {
    position: absolute;
    top: 2vh;
    left: 20vw;
    right: 20vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: scroll;
    height: 500px;
    min-width: 250px;
    outline: none;
  }

.TimeOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .averageinfo {
    position: relative;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .avinfo {
    margin: 30px 0px 30px -1vw;
    font-size: 1.5rem;
  }

  .category-time {
    width: 100px;
    text-align: left;
  }

  .category-solve {
    width: 100px;
    text-align: center;
  }

  .tablehead {
    height: 2em;
    text-align: left;
    position: fixed;
    margin-top: -40px;
    padding-bottom: 10px;
    font-size: 24px;
  }

  .tableview {
    display: block;
    background-color: var(--buttonhover);
    height: 300px;
    width: 60vw;
    overflow: scroll;
    font-size: 16px;
  }

  .graphview {
    display: block;
    height: 300px;
    width: 60vw;
    overflow: scroll;
    font-size: 16px;
  }

  .avbuttons {
    position: absolute;
    top: 440px;
    display: inline-block;
    right: 1vw;
  }

  .avstats {
    position: fixed;
    font-size: 24px;
    width: 10px;
    height: 10px;
    top: calc(2vh + 80px);
    right: calc(20vw + 70px);
  }

  .avstats:hover, .avstats:focus {
    background-color: var(--primary);
  }

  .copy {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  #titleav {
    margin: 0px;
    margin-left: .5vw;
    font-size: 40px;
    color: var(--texthighlighted);
  }

  #clear {
    font-size: 1vw;
  }

  .on {
    color: var(--accent);
    transition: color .3s ease, background-color 0s ease;
  }

  .off {
    color: var(--text);
    transition: color .3s ease, background-color 0s ease;
  }

  .off:hover {
    color: var(--texthighlighted);
    transition: color .3s ease, background-color 0s ease;
  }

#log {
  justify-content: left;
  margin-left: 0px;
  width: 15vw;
  height: 100vh;
  background-color: inherit;
  display: block;
  position: absolute;
  align-items: left;
  color: var(--text);
  transition: color .5s, background-color 0s;
  font-size: 16px;
  min-width: 200px;
  overflow: hidden;
}

#log:hover {
  color: var(--highlight);
}

.logstats {
  background-color: var(--primary);
  width: 15vw;
  height: 317px;
  display: inline-block;
  min-width: 200px;
  overflow: hidden;
}

.scroll {
  display: block;
  overflow-x: hidden;
  position: relative;
  height: calc(100vh - 364px);
  border-top-style: solid;
  border-width: 1px;
  margin-right: -30px;
}

.av {
  background-color: var(--primary);
  display: block;
  position: fixed;
  align-items: center;
  bottom: 0;
  width: 15vw;
  height: 30px;
  padding-top: 10px;
  border-top-style: solid;
  border-width: 1px;
  min-width: 200px;
  overflow: hidden;
  justify-content: center;
}

.row {
  display: flex;
}

.toprow {
  font-weight: lighter;
  margin-right: 0vw;
  display: flex;
  justify-content: left;
}

.quarter {
  width: 3.5vw;
  display: inline-block;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  min-width: 47px;
  cursor: default;
}

button:hover {
  cursor: pointer;
}

.results {
  width: 100%;
}

button {
  background-color: inherit;
  opacity: 100;
  width: 100%;
  height:100%;
  display: block;
  outline: none;
  border: none;
  padding: 5px;
  color: inherit;
  font-size: 15px;
}

button:hover {
  background-color: rgba(0, 0, 0, .2);
}

button:hover #step {
  display:none;
}

button #step {
  display: block;
}

button:hover #delete {
  display: block;
  font-weight: lighter;
}

button #delete {
  display: none;
}

#settings {
  background-color: inherit;
  font-size: 10px;
}

ul {
  list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .1);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, .2);
}

.CustomModal {
    position: absolute;
    top: 12vh;
    left: 25vw;
    right: 25vw;
    background-color: var(--primary);
    color: var(--texthighlighted);
    overflow: scroll;
    height: 250px;
    min-width: 250px;
    outline: none;
  }

.ArrowOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .clearinfo {
    position: relative;
    margin: 10px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .choose {
    position: absolute;
    top: 180px;
    display: inline-block;
    right: 1%;
  }

  .cancel {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .confirm {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }

  .nameinput {
    background-color: rgb(0, 0, 0, .2);
    color: var(--text);
    outline-color: var(--accent);
    font-size: 16px;
    border-style: none;
    margin: 20px;
    padding: 5px;
    height: 30px;
    width: 250px;
    margin-left: 1vw;
  }

#title {
    margin: 0px;
    color: var(--texthighlighted);
  }

  #clear {
    font-size: 1vw;
  }


  .savetheme {
    margin-right: 20px;
    margin-top: 0px;
    margin-left: 25px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: var(--accent);
    --button-primary-color-dark: var(--dark);
    --button-primary-color-light: var(--texthighlighted);
    --button-primary-color-hover: var(--hover);
    --button-primary-color-active: var(--click);
    --button-primary-border: 0px solid var(--texthighlighted);
  }

.Modal {
    position: absolute;
    top: 10vh;
    left: 10vw;
    right: 10vw;
    bottom: 10vh;
    background-color: var(--primary);
    color: var(--texthighlighted);
    outline: none;
    transition: .2s;
  }

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  .setmodalinfo {
    margin-right: 0px;
    height: 100%;
  }

  .setmodalinfo > h {
    font-size: 48px;
  }

  .switches {
    float: right;
    padding-right: 30px;
    padding-top: 15px;
  }

  .setinput {
    background-color: rgb(0, 0, 0, .3);
    color: var(--texthighlighted);
    border-style: none;
    padding: 5px;
    width: 24px;
  }

  #changen {
    font-size: 28px;
    margin: -10px 5px -5px;
    color: var(--accent);
  }

  #changen:hover {
    color: var(--hover);
    cursor: pointer;
  }

  #changen:active {
    color: var(--click);
  }

  #icon {
    font-size: 2rem;
  }

  #icon:hover {
    background-color: inherit;
    border-radius: 10px;
  }

  .tabs {
    float: left;
    display: block;
    width: 15%;
    height: 100%;
    min-width: 100px;
    margin-right: 0px;
    margin-top: 0px;
  }

  .setting {
    color: var(--texthighlighted);
    width: 100%;
    display: inline-block;
    font-size: 1.25rem;
    height: 60px;
    margin: 0 0 0 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid;
    border-bottom-color: var(--text);
    border-bottom-width: 2px;
    overflow: hidden;
  }

  .desc {
    color: var(--text);
    margin-left: 20px;
    padding-bottom: 5px;
  }

  .presets {
    color: var(--texthighlighted);
    width: 100%;
    display: inline-block;
    font-size: 1.5rem;
    height: 120px;
    margin: 0 0 0 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid;
    border-bottom-color: var(--text);
    border-bottom-width: 2px;
    overflow: auto;
  }

  .select {
    margin-top: 30px;
    margin-left: 30px;
  }

  .custom {
    color: var(--texthighlighted);
    width: 100%;
    display: inline-block;
    font-size: 1.5rem;
    margin: 0 0 0 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 500px;
    border-bottom: solid;
    border-bottom-color: var(--text);
    border-bottom-width: 2px;
  }

  .theme {
    margin: 30px;
  }

  .colorbuttons {
    float: left;
    display: inline-block;
    width: 200px;
    margin-bottom: 50px;
    margin-right: 30px;
  }

  .colors {
    display: inline-block;
    margin: 0px 0px 50px 0px;
    overflow: auto;
  }

  .saved {
    overflow: auto;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    height: 200px;
    overflow-x: hidden;
  }

  .colorscontent {
    overflow: scroll;
    height: calc(80vh - 72px);
    overflow-x: hidden;
  }

  .unstyledlist {
    margin: 0px 30px;
    width: 100%;
    padding: 0px;
    overflow-x: hidden;
  }

  .listitem {
    display: inline-block;
    text-align: center;
    margin-right: 30px;
  }

  .themebtn {
    width: 160px;
    height: 42px;
    font-weight: 700;
    font-size: .65em;
    transition: .2s;
  }

  .themebtn:hover {
    cursor: pointer;
  }

  .deletetheme {
    display: block;
    width: 30px;
    height: 42px;
    margin-top: 30px;
    margin-left: 160px;
    margin-bottom: -42px;
  }

  .deletetheme:hover {
    color: var(--texthighlighted);
    background-color: inherit;
    cursor: pointer;
  }

  .deletetheme:active {
    color: var(--texthighlighted);
  }

  #tinyshift {
    margin-left: 15px;
  }

  .setlist {
    overflow: scroll;
    height: calc(80vh - 72px);
  }



  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    height: 100%;
  }

  .react-tabs__tab-list {
    margin: 0 0 0 0px;
    padding: 0;
  }

  .react-tabs__tab {
    color: var(--text);
    display: block;
    background: var(--tab);
    font-size: 1.5rem;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: .2s;
  }

  .react-tabs__tab:hover {
    background: var(--tabselect);
  }

  .react-tabs__tab--selected {
    background: var(--tabselect);
    color: var(--texthighlighted);
  }

  .react-tabs__tab--disabled {
    color: var(--texthighlighted);
    cursor: default;
    padding: 0px;
    font-size: 2rem;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .react-tabs__tab--disabled:hover {
    background-color: inherit;
  }

  .react-tabs__tab:focus {
    outline: none;
  }

  .react-tabs__tab-panel {
    display: none;
    overflow: hidden;
    margin: 0px;
  }

  .react-tabs__tab-panel--selected {
    display: block;
    margin-top: 0px;
    margin-left: 10px;
    height: 100%;

  }

  .pages {
    margin-top: 0px;
    display: inline-block;
    position: relative;
    width: calc(82% - 30px);;
    height: 100%;
    margin-right: 30px;
    overflow: auto;
    overflow-x: hidden;
  }

  .gray-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #282c34;
    --button-primary-color-dark: #0d0f11;
    --button-primary-color-light: #d5dce2;
    --button-primary-color-hover: #1f2228;
    --button-primary-color-active: #1d2026;
    --button-primary-border: 0px;
  }

  .dark-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #1a1c21;
    --button-primary-color-dark: #000000;
    --button-primary-color-light: #d5dce2;
    --button-primary-color-hover: #131519;
    --button-primary-color-active: #0d0e11;
    --button-primary-border: 0px;
  }

  .light-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #d5dce2;
    --button-primary-color-dark: #a2a6aa;
    --button-primary-color-light: #282c34;
    --button-primary-color-hover: #bfc4c9;
    --button-primary-color-active: #b8bcc1;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .blue-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #3da1ff;
    --button-primary-color-dark: #235f99;
    --button-primary-color-light: #0b1c2d;
    --button-primary-color-hover: #3186d6;
    --button-primary-color-active: #2a76bf;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .dark-blue-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #000b16;
    --button-primary-color-dark: #000;
    --button-primary-color-light: #91c2ff;
    --button-primary-color-hover: #00070f;
    --button-primary-color-active: #2a76bf;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .red-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #ff4949;
    --button-primary-color-dark: #b23333;
    --button-primary-color-light: #163859;
    --button-primary-color-hover: #e84343;
    --button-primary-color-active: #d13c3c;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .dark-red-theme {
    margin-right: 20px;
    margin-bottom: 10px;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 2px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: #1c0000;
    --button-primary-color-dark: #000;
    --button-primary-color-light: #ff6363;
    --button-primary-color-hover: #380b0b;
    --button-primary-color-active: #230707;
    --button-primary-border: 0px solid #FFFFFF;
  }

  .primary {
    margin-right: 20px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 15px var(--accent);
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--primary);
    --button-primary-color-dark: var(--primary);
    --button-primary-color-light: var(--text);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--primary);
    --button-primary-border: 1px solid var(--text);
  }

  .secondary {
    margin-right: 20px;
    margin-bottom: 30px;
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--secondary);
    --button-primary-color-dark: var(--secondary);
    --button-primary-color-light: var(--primary);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--secondary);
    --button-primary-border: 0px solid var(--text);
  }

  .accent {
    margin-right: 20px;
    margin-bottom: 30px;
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--accent);
    --button-primary-color-dark: var(--accent);
    --button-primary-color-light: var(--texthighlighted);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--accent);
    --button-primary-border: 0px solid var(--texthighlighted);
  }

  .text {
    margin-right: 20px;
    margin-bottom: 30px;
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--text);
    --button-primary-color-dark: var(--text);
    --button-primary-color-light: var(--primary);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--text);
    --button-primary-border: 0px solid var(--texthighlighted);
  }

  .texthighlighted {
    margin-right: 20px;
    margin-bottom: 30px;
    transition: .2s;
    --button-default-height: 51px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 0px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.15s;
    --button-primary-color: var(--texthighlighted);
    --button-primary-color-dark: var(--texthighlighted);
    --button-primary-color-light: var(--primary);
    --button-primary-color-hover: var(--buttonhover);
    --button-primary-color-active: var(--texthighlighted);
    --button-primary-border: 0px solid var(--texthighlighted);
  }

.AccountModal {
    position: absolute;
    top: 2vh;
    left: 10vw;
    right: 10vw;
    background-color: var(--primary);
    color: var(--text);
    overflow: scroll;
    bottom: 42vh;
    min-width: 250px;
    outline: none;
  }

.AccountOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
  }

  #accountbtn {
    font-size: 2rem;
  }

  #accountbtn:hover {
    background-color: inherit;
    border-radius: 10px;
  }

  .signinbtn {
    text-align: center;
    height: 50px;
    width: 240px;
    margin-top: 100px;
    margin-left: calc(50% - 120px);
  }

  .logout {
    text-align: center;
    bottom: calc(42vh + 30px);
    right: calc(10vw + 30px);
    position: fixed;
    width: 240px;
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 2px;
    background-color: var(--accent);
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 4px 0px;
    transition: box-shadow .2s ease;
  }

  .logout:hover {
    background-color: var(--accent);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px 0px;
    cursor: pointer;
  }

  .accountinfo {
    position: relative;
    margin: 10px;
    height: 450px;
    padding-left: 1vw;
    padding-top: 1vh;
    padding-right: 1vw;
    padding-bottom: 1vh;
  }

  .signininfo {
    position: relative;
    font-size: 24px;
    text-align: center;
    margin: 0px 20px;
    height: 390px;
  }

  .signin {
    width: 20vw;
    text-align: center;
    justify-content: center;
  }

  .userinfo {
    margin: 30px;
  }

  .welcome {
    margin-top: 30px;
    display: inline-block;
    font-size: 30px;
  }

  .profilepic {
    width: 100px;
    margin-bottom: -30px;
    margin-right: 30px;
  }

  .accountdesc {
    font-size: 20px;
    margin-left: 20px;
    margin-top: 50px
  }

  .updownbtns {
    padding-right: 50px;
    padding-left: 50px;
  }

  .load-title {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 30px;
    display: inline-block;
  }

  .save-title {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 32px;
    display: inline-block;
  }


  .upbtnaccount {
    text-align: left;
    margin: 10px 0px;
  }

  .downbtnaccount {
    text-align: left;
  }

  .actload {
    text-align: center;
    display: inline-block;
    position: fixed;
  }

  .actsave {
    text-align: left;
    display: inline-block;
    position: fixed;
  }

  .timestamp-act {
    margin: 10px 20px;
    font-size: 20px;
  }

.StatsModal {
    position: absolute;
    width: 35vw;
    height: 35vh;
    bottom: 0;
    right: 0vw;
    background-color: var(--primary);
    color: var(--texthighlighted);
    outline: none;
    transition: color .2s ease, background-color 0s ease;
    overflow: auto;
  }

  .stattab {
    width: 100%;
    bottom: 0;
    position: absolute;
  }

  .tabbtn {
    height: 3rem;
    font-size: 1.5em;
    width: 33.333%;
    color: var(--text);
    display: inline-block;
    line-height: 2rem;
    opacity: .7;
  }

  .tabbtnselected {
    height: 3rem;
    font-size: 1.5em;
    width: 33.333%;
    display: inline-block;
    color: var(--texthighlighted);
    line-height: 2rem;
  }

  .tabbtn:hover, .tabbtn:focus, .tabbtn:active {
    background-color: inherit;
    opacity: 1;
  }

  .tabbtnselected:hover, .tabbtnselected:focus, .tabbtnselected:active {
    background-color: inherit;
  }

  .graphpanel {
    bottom: 0;
    position: absolute;
    width: 98%;
    margin-left: 0%;
  }

  .statpanel {
    margin-top: 20px;
    position: absolute;
    width: 99%;
    font-size: 16px;
  }

  #stat {
    width: 33%;
  }

  .stat-table {
    width: 100%;
    color: var(--text);

  }

  .blanktable {
    margin: 25px 0px;
    display: block;
  }

  .dropdown {
    background-color: var(--primary);
    color: var(--text);
    border: none;
    width: 35vw;
    bottom: 36.5vh;
    right: 0;
    position: fixed;
  }

  .dropdown-menu {
    background-color: var(--primary);
    color: var(--text);
    border: none;
    box-shadow: none;
  }

  .dropdown-ctrl {
    background-color: var(--primary);
    color: var(--text);
    border: none;
    transition: color .3s ease, background-color 0s ease;
  }

  .dropdown-ctrl:hover {
    cursor: pointer;
    box-shadow: none;
  }

  .dropdown-place {
    margin-left: 40px;
  }

  .dropdown-item {
    background-color: var(--primary);
    color: var(--text);
    border: none;
    transition: color .2s ease, background-color 0s ease;
  }

  .dropdown-item:hover {
    background-color: var(--primary);
    color: var(--texthighlighted);
  }

  .dropdown-item.is-selected {
    background-color: var(--accent);
    color: var(--texthighlighted);
  }

.scrambletext {
  max-height: calc(30vh - 50px);
  overflow: auto;
}

.scramble-dropdown {
  background-color: var(--primary);
  border: none;
  font-size: 18px;
  font-weight: 500;
  width: 60vw;
  padding-top: 10px;
}

.scramble-dropdown:hover {
  text-decoration: none;
}

.scramble-dropdown-menu {
  background-color: var(--primary);
  color: var(--text);
  border: none;
  box-shadow: none;
  overflow: visible;
}

.scramble-dropdown-ctrl {
  background-color: var(--primary);
  color: inherit;
  border: none;
  text-align: center;
  transition: none;
}

.scramble-dropdown-ctrl:hover {
  cursor: pointer;
  box-shadow: none;
}

.scramble-dropdown-place {
  text-align: center;
  margin-left: 40px;
}

.scramble-dropdown-place:hover {
  border: none;
}

.scramble-dropdown-item {
  background-color: var(--primary);
  color: var(--text);
  font-size: 18px;
  border: none;
  transition: color .2s ease, background-color 0s ease;
}

.scramble-dropdown-item:hover {
  background-color: var(--primary);
  color: var(--texthighlighted);
}

.scramble-dropdown-item.is-selected {
  background-color: var(--accent);
  color: var(--texthighlighted);
}

.scramble-dropdown-arrow {
  opacity: 0;
}

:root {
  --primary: #1a1c21;
  --secondary: #efefef;
  --accent: #9621ff;
  --text: rgba(255, 255, 255, .6);
  --texthighlighted: #efefef;
  --dark: #0d0f11;
  --hover: #1f2228;
  --click: #1d2026;

  --tabselect: rgba(0, 0, 0, .3);
  --buttonhover: rgba(0, 0, 0, .2);
  --tab: var(--primary);

  --highlight: var(--texthighlighted);
}

.timer {
  text-align: center;
  background-color: #1a1c21;
  background-color: var(--primary);
  transition: 0s;
  color: #efefef;
  color: var(--secondary);
}

#time {
  color: inherit;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 144px;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#average {
  bottom: 0vh;
  left: 30vw;
  right: 30vw;
  height: flex;
  background-color: inherit;
  display: block;
  padding-bottom: 20px;
  position: absolute;
  align-items: center;
  vertical-align: bottom;
  color: rgba(255, 255, 255, .6);
  color: var(--text);
  transition: color .5s, background-color 0s;
  font-size: 24px;
  min-width: 200px;
  cursor: default;
}

#average:hover {
  color: #efefef;
  color: var(--highlight);
  cursor: default;
}

#scramble {
  left: 20vw;
  right: 20vw;
  height: flex;
  max-height: 30vh;
  background-color: inherit;
  display: block;
  position: absolute;
  align-items: center;
  color: rgba(255, 255, 255, .6);
  color: var(--text);
  transition: color .5s, background-color 0s;
  font-size: 28px;
  min-width: 200px;
}

#scramble:hover {
  color: #efefef;
  color: var(--highlight);
}

#settings {
  top: 3em;
  right: 1vw;
  background-color: inherit;
  display: block;
  position: absolute;
  color: rgba(255, 255, 255, .6);
  color: var(--text);
  transition: color .3s ease, background-color 0s ease;
}

#settings:hover {
  color: #efefef;
  color: var(--highlight);
}

#icon:hover, #accountbtn:hover, #statsicon:hover {
  cursor: pointer;
}

#account {
  top: 10em;
  right: 1vw;
  background-color: inherit;
  display: block;
  position: absolute;
  color: rgba(255, 255, 255, .6);
  color: var(--text);
  transition: color .3s ease, background-color 0s ease;
}

#account:hover {
  color: #efefef;
  color: var(--highlight)
}

#stats {
  top: 6em;
  right: 1vw;
  background-color: inherit;
  display: block;
  position: absolute;
  color: rgba(255, 255, 255, .6);
  color: var(--text);
  transition: 0s;
}

#statsicon {
  font-size: 1.9rem;
  transition: color .3s ease, background-color 0s ease;
}

#statsicon:hover {
  background-color: inherit;
  border-radius: 10px;
  cursor: pointer;
  color: #efefef;
  color: var(--highlight);
  transition: color .3s ease, background-color 0s ease;
}

#statsiconon {
  font-size: 1.9rem;
  color: #9621ff;
  color: var(--accent);
  transition: color .3s ease, background-color 0s ease;
}

#statsiconon:hover {
  background-color: inherit;
  border-radius: 10px;
  cursor: pointer;
}

#undoload {
  bottom: 0vh;
  width: 118px;
  height: 80px;
  display: block;
  position: absolute;
  right: calc(50vw - 59px);
  background-color: rgba(0, 0, 0, 0);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

input {
  transition: borderColor .3s ease;
}

@media screen and (max-width: 1080px) {

    #average {
      bottom: 0%;
      left: 0vw;
      right: 0vw;
      height: 10%;
      transition: 0s;
      font-size: 24px;
      width: 100vw;
    }

    #scramble {
      left: 15%;
      right: 15%;
    }

    .scramble-dropdown {
      width: 100%;
    }

    .log {
      top: 100%;
      width: 100vw !important;
    }

    .logstats {
      width: 100vw !important;
    }

    .scroll {
      width: 100vw !important;
    }

    .av {
      width: 100vw;
      position: absolute;
    }

    .third {
      width: 33%;
    }

    .quarter {
      width: 25%;
    }

    .StatsModal {
      bottom: 250%;
      top: 200%;
      width: 100vw;
    }

    .dropdown {
      width: 100vw;
      float: left;
      position: absolute;
      top: 0%;
      bottom: auto;
    }

    .stat-table {
      margin-top: 37px;
    }

    .Modal {
      top: 10%;
      bottom: 10%;
      left: 5%;
      right: 5%;
      float: right;
    }

    .presets {
      height: 50%;
    }

    .theme {
      display: none;
    }

    .tabs {
      display: inline-block;
      width: 100%;
      height: 135px;
      min-width: 100px;
      margin-right: 0px;
      margin-top: 0px;
    }

    .react-tabs__tab {
      display: inline-block;
      width: 33.3%;
    }

    .pages {
      width: 86vw;
      margin: 0px 2vw 0px 2vw;
      height: calc(100% - 135px);
    }

    .react-tabs__tab-panel--selected {
      margin-left: 0px;
      overflow: auto;
    }

    h2 {
      display: none;
    }

    .colorscontent {
      text-align: center;
    }

    .presets {
      height: 45%;
      margin: 0px;
      overflow: auto;
    }

    .custom {
      text-align: center;
      margin: 0px;
    }

    .colorbuttons {
      float: none;
      margin: 0px;
      margin-bottom: 30px;
    }

    .saved {
      height: 300px;
      text-align: center;
    }

    .btnlist {
      margin: 0px;
    }

    .unstyledlist {
      margin: 0px;
    }

    .listitem {
      margin: 0px 20px;
    }

    .setting {
      height: auto;
      width: 86vw;
    }

    .AccountModal {
      height: 80%;
    }

    .logout {
      bottom: 21%;
    }

    .SessionModal {
      right: 10vw;
      left: 10vw;
    }

    .addsess {
      width: 80vw;
    }

    .DeleteSessionModal {
      right: 10vw;
      left: 10vw;
    }

    .DownUpModal {
      top: 2%;
      left: 2%;
      right: 2%;
      bottom: 20%;
      height: auto;
    }

    .downupinfo {
      margin: 0px;
      height: calc(100% - 50px);
      overflow: auto;
    }

    .transferbtn {
      float: right;
      margin-right: 30px;
      width: 88px;
    }

    .transfer {
      height: 150px;
    }

    .transferinfo {
      margin-left: 30px;
      width: 100%;
      display: inline-block;
    }

    #h2du {
      text-align: left;
      margin-left: 20px;
      display: block;
    }

    .lefttitle {
      width: 50%;
      display: inline-block;
      text-align: center;
      color: rgba(255, 255, 255, .6);
      color: var(--text);
      margin: 10px 0px;
    }

    .righttitle {
      width: 50%;
      display: inline-block;
      text-align: center;
      color: rgba(255, 255, 255, .6);
      color: var(--text);
      margin-top: 10px;
    }

    .leftdu {
      width: calc(100% - 20px);
      color: rgba(255, 255, 255, .6);
      color: var(--text);
      display: inline-block;
      height: auto;
    }

    .rightdu {
      width: calc(100% - 20px);
      color: rgba(255, 255, 255, .6);
      color: var(--text);
      display: inline-block;
      height: auto;
    }

    .ArrowModal {
      top: 1%;
      left: 2%;
      right: 2%;
    }

    .TimeModal {
      left: 2%;
      right: 2%;
    }

    .averageinfo {
      padding: 1% 1% 1% 1%;
    }

    .avinfo {
      margin: 30px -1% 30px -1%;
    }

    .tableview {
      width: 100%;
    }

    .graphview {
      width: 100%;
    }

    .avstats {
      top: calc(1% + 80px);
      right: calc(2% + 34px);
    }

    .AboutModal {
      height: auto;
      top: 2%;
      left: 2%;
      right: 2%;
      bottom: 30%;
    }

    .averageinfo {
      height: 98%;
    }

    .avbuttons {
      top: calc(100% - 50px);
    }

    .AccountModal {
      top: 2%;
      right: 2%;
      left: 2%;
      bottom: 15%;
      height: auto;
    }

    .accountinfo {
      height: calc(100% - 35px);
      overflow-x: hidden;
    }

    .accountdesc {
      margin-top: 20px;
    }

    .userinfo {
      margin: 10px;
    }

    .downloadbtn {
      margin: 0;
    }

    .logout {
      position: relative;
      display: inline-block;
      margin-left: calc(100% - 180px);
      margin-top: 30px;
      margin-bottom: 30px;
    }

  }

