.scrambletext {
  max-height: calc(30vh - 50px);
  overflow: auto;
}

.scramble-dropdown {
  background-color: var(--primary);
  border: none;
  font-size: 18px;
  font-weight: 500;
  width: 60vw;
  padding-top: 10px;
}

.scramble-dropdown:hover {
  text-decoration: none;
}

.scramble-dropdown-menu {
  background-color: var(--primary);
  color: var(--text);
  border: none;
  box-shadow: none;
  overflow: visible;
}

.scramble-dropdown-ctrl {
  background-color: var(--primary);
  color: inherit;
  border: none;
  text-align: center;
  transition: none;
}

.scramble-dropdown-ctrl:hover {
  cursor: pointer;
  box-shadow: none;
}

.scramble-dropdown-place {
  text-align: center;
  margin-left: 40px;
}

.scramble-dropdown-place:hover {
  border: none;
}

.scramble-dropdown-item {
  background-color: var(--primary);
  color: var(--text);
  font-size: 18px;
  border: none;
  transition: color .2s ease, background-color 0s ease;
}

.scramble-dropdown-item:hover {
  background-color: var(--primary);
  color: var(--texthighlighted);
}

.scramble-dropdown-item.is-selected {
  background-color: var(--accent);
  color: var(--texthighlighted);
}

.scramble-dropdown-arrow {
  opacity: 0;
}
